import Vue from 'vue';
import store from '@/store';
import BaseLayout from '@/components/BaseLayout.vue';
import vuetify from '@/plugins/vuetify';
import router from '@/router';
import '@/plugins/vue-frag';
import moment from 'moment';
import App from './App.vue';

Vue.component('base-layout', BaseLayout);

Vue.prototype.$assetsEndpoint = process.env.VUE_APP_ASSET_ENDPOINT;

Vue.config.productionTip = false;

Vue.filter('formatDate', (value) => moment(value).format('YYYY-MM-DD HH:mm:ss'));

store.dispatch('Auth/autoLogin').then(() => {
  new Vue({
    router,
    vuetify,
    store,
    render: (h) => h(App),
  }).$mount('#app');
});
